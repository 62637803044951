'use client';

import { useRouter as useNextRouter, usePathname } from 'next/navigation';
import { useEffect, useState } from 'react';
import { usePageLoading } from './PageLoadingProvider';

export const useRouterWithLoading = () => {
  const router = useNextRouter();
  const pathname = usePathname();
  const { setLoading } = usePageLoading();
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    setIsMounted(true);
  }, []);

  useEffect(() => {
    if (isMounted) {
      setLoading(false); // Включаем прелоадер на клиенте при переходе
    }

    return () => setLoading(false); // Выключаем прелоадер, когда переход завершён
  }, [pathname, isMounted, setLoading]);

  const push = (href: string) => {
    setLoading(true);
    router.push(href);
  };

  const replace = (href: string) => {
    setLoading(true);
    router.replace(href);
  };

  return {
    ...router,
    push,
    replace,
  };
};
